import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import KeyInformationView from './key-informations-view'

const KeyInformationController = () => {
  const [loading, setLoading] = useState(false)
  const [keyInfo, setKeyInfo] = useState({})
  const [dataFiltered, setDataFiltered] = useState([])
  const { i18n } = useTranslation()
  const controller = new AbortController()

  const getKeyInformation = async () => {
    setLoading(true)
    setKeyInfo([])
    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      signal: controller.signal,
      endPoint: 'claims-support/key-information',
      params: {
        lang: i18n.language,
      },
    })
      .then(({ data }) => {
        setKeyInfo(data)
        setDataFiltered(_.get(data, 'content[0]'))
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onHandelFilterByGroup = (group) => {
    const dataByFilter = _.head(_.filter(keyInfo.content, { slug: group }))
    setDataFiltered(dataByFilter)
  }

  useEffect(() => {
    getKeyInformation()
    return () => {
      controller.abort()
    }
  }, [i18n.language])

  const viewProps = {
    loading,
    data: keyInfo,
    onHandelFilterByGroup,
    dataFiltered,
  }
  return <KeyInformationView {...viewProps} />
}

export default KeyInformationController
